<template>
  <div class="main index">
    <div class="index_banner_box container">
      <div class="center">
        <el-carousel :height="bannerH + 'px'" :interval="10000">
          <el-carousel-item>
            <div class="banner_link" @click="$router.push('Awards/Entry')">
              <img ref="bannerH" src="@/assets/images/b1.jpg" />
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/images/b2.jpg" />
            <div
              class="banner_link1"
              @click="$router.push('WorldDetail?cat=1')"
            ></div>
            <div
              class="banner_link2"
              @click="$router.push('WorldDetail?cat=2')"
            ></div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="banner_link" @click="$message('Coming Soon')">
              <img src="@/assets/images/b3.jpg" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="container">
      <div class="news_box">
        <div class="news_item" v-for="(item, index) in list" :key="index">
          <div class="img">
            <img class="img" :src="item.list_img" />
          </div>
          <div class="con">
            <div class="title">{{ item.news_title }}</div>
            <div class="text" v-html="item.news_abstract"></div>
            <div class="readmore" @click="toDetail(item.id)">READ MORE</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_NEWS } from '@/api/index.js';

export default {
  name: 'Index',
  components: {},
  data() {
    return {
      list: [],
      bannerH: 100,
    };
  },
  created() {
    // this.ok();
    this.getList();
    setTimeout(() => {
      this.bannerResize();
    }, 500);
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.bannerResize();
    });
  },

  methods: {
    bannerResize() {
      this.$nextTick(() => {
        this.bannerH = this.$refs.bannerH.offsetHeight;
      });
    },
    getList() {
      this.list = [];
      GET_NEWS().then(res => {
        console.log(res);
        for (let i = 0; i < 3; i++) {
          this.list.push(res.data[i]);
        }
      });
    },
    toDetail(id) {
      console.log(id);
      this.$router.push({
        path: '/NewsMedia/NewsDetail',
        query: {
          id,
        },
      });
    },
    // ok() {
    //   INDEX({})
    //     .then(res => {})
    //     .catch(() => {});
    // },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
.banner_link {
  cursor: pointer;
}
.banner_link1 {
  cursor: pointer;
  position: absolute;
  width: 100px;
  height: 50px;
  left: 16%;
  top: 30%;
}
.banner_link2 {
  cursor: pointer;
  position: absolute;
  width: 100px;
  height: 50px;
  left: 16%;
  top: 65%;
}
</style>
